import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Drawer, Input, Popconfirm, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { Fragment, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Slick
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import { ReactComponent as ViewpointDelete } from '../../../../assets/svgs/viewpoint-delete.svg'
import { ReactComponent as ViewpointUpdate } from '../../../../assets/svgs/viewpoint-update.svg'
import { addViewPointCameraData, checkingUpdateProjectThumbnail, getVisileDataTree, startReRender } from '../../../../lib/projectLib'
import { ProjectRequest } from '../../../../requests'
import HelpButton from '../../../elements/HelpButton'
import SVGIcon from '../../../elements/SVGIcon'
import { createImageFileFromBase64, goViewpoint } from '../../../helper/CesiumUtils'
import {
  CardViewWrapper, CustomDrawerWraper, DrawerHeading, ViewPointImage,
  ViewPointImageContainer
} from './CustomStyled'
import './style.css'
import { toJS } from 'mobx'

const DrawerCaptureViews = props => {
  const { capturesStore, projectStore, schedulingStore, projectSettingStore, adminStore, viewer, viewerRef, feedbackStore, sketchingStore, topicStore, commonStore, location, polylineCutToolStore, usersStore } = props
  const { captureList } = capturesStore
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const history = useHistory()

  const addViewpoint = () => {
    capturesStore.setClickAdd(true)
  }
  useEffect(() => {
  }, [adminStore.currentFeatureSetting, adminStore.systemFeatureSetting, adminStore.projectFeatureSetting])

  const drawerTitle = () => {
    return (
      <DrawerHeading>
        {
          adminStore.checkingFeatureRole(projectStore, "feature_save_view_edit") && projectStore.isExistLicenses && (
            <Button
              onClick={() => addViewpoint()}
              size="small"
              type={'primary'}
              loading={capturesStore.isLoading}>
              <PlusOutlined />{t('save-view')}
            </Button>
          )
        }
      </DrawerHeading>
    )
  }

  const sliderSetting = {
    dots: false,
    arrows: true,
    slidesToShow: 12,
    slidesToScroll: 12,
    infinite: false,
    centerPadding: 10,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const [modelEdit, setModelEdit] = useState(undefined)
  const inputEl = useRef(null)

  const onClickHandler = (e, captureId) => {
    if (e.detail === 2 && !projectStore.visitedMode) {
      setModelEdit(captureId)
    }
  }

  useEffect(() => {
    if (modelEdit !== undefined) {
      inputEl.current.focus()
      inputEl.current.select()
    }
  }, [modelEdit])

  const getImageFromCanvasLess = async (filename, width, height, quality) => {
    if (viewerRef.current !== null) {
      startReRender(viewerRef);
      viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = false;
      viewerRef.current.cesiumElement.render();

      const canvas = viewerRef.current.cesiumElement.canvas;
      const originalWidth = canvas.width;
      const originalHeight = canvas.height;

      // Create a new canvas with the desired size
      const newCanvas = document.createElement('canvas');
      newCanvas.width = width;
      newCanvas.height = height;
      const context = newCanvas.getContext('2d');

      // Draw the image from the original canvas onto the new canvas with resizing
      context.drawImage(
        canvas,
        0,
        0,
        originalWidth,
        originalHeight,
        0,
        0,
        width,
        height
      );

      const base64Image = newCanvas.toDataURL('image/jpeg', quality / 100);
      const imageFile = await createImageFileFromBase64(base64Image, filename);
      const formData = new FormData();
      formData.append('files', imageFile);

      viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = projectStore.showAmbientOcclusion;

      return ProjectRequest.uploadModel(formData);
    }
    return Promise.reject();
  };

  const getCameraData = async (treeData, updateImage, nameImage) => {
    if (viewerRef.current != null) {
      let camera = viewerRef.current.cesiumElement.camera
      let camData = {}
      camData.position = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      }
      camData.direction = {
        x: camera.direction.x,
        y: camera.direction.y,
        z: camera.direction.z,
      }
      camData.up = {
        x: camera.up.x,
        y: camera.up.y,
        z: camera.up.z,
      }
      camData.positionCartographic = {
        lng: camera.positionCartographic.longitude,
        lat: camera.positionCartographic.latitude,
        elevation: camera.positionCartographic.height
      }
      camData.orientation = {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: camera.roll
      }
      camData.timeSlider = schedulingStore.currentViewingTime || moment(new Date())
      let newCapture = {
        cameraData: camData,
        treeData
      }
      if (updateImage && nameImage) {
        let name = (nameImage.split(' ')).reduce((a, b) => a + b)
        projectStore.setLoadingProgress(true)
        const files = await getImageFromCanvasLess(
          `${name}.jpeg`, 265, 175, 80
        )
        projectStore.setLoadingProgress(false)
        const file = files.data[0]
        newCapture.image = file
      }

      return newCapture
    }
    return false
  }

  const onSaveHandler = async (key, capture, captureName) => {
    if (key === 'Enter' && inputEl.current.input.value.trim()) {
      if (captureName === inputEl.current.input.value.trim()) {
        setModelEdit(undefined)
        return
      }
      const startTime = Date.now();
      let cam = {}
      cam.name = inputEl.current.input.value.trim()
      cam.action = 'rename'
      const temp = [...captureList].sort((a, b) => a.name.localeCompare(b.name))
      let newIndex;
      let isUpdateThumnail = false;
      let oldIndex = temp.findIndex(c => c.id === capture.id);
      if (oldIndex !== -1) {
        temp[oldIndex].name = inputEl.current.input.value.trim();
        const newtemp = temp.sort((a, b) => a.name.localeCompare(b.name))
        newIndex = newtemp.findIndex(c => c.id === capture.id);
      }
      if (oldIndex === 0 && newIndex !== 0 || oldIndex !== 0 && newIndex === 0) {
        isUpdateThumnail = projectStore?.projectDetail?.id;
      }
      if (!projectStore.projectDetail?.thumbnailFromViewpoint) {
        isUpdateThumnail = false;
      }

      capturesStore
        .updateCaptureView(capture.id, cam, true, isUpdateThumnail, {
          startTime,
          user: usersStore.currentUser,
          project: projectStore?.projectDetail?.name,
          organization: projectStore?.projectDetail?.organization?.name,
        }, updateProjectDetail)
        .then(() => setModelEdit(undefined))
    }
  }

  const updateCaptureViews = async (capture) => {
    const startTime = Date.now();
    let data = getVisileDataTree(projectStore, topicStore, sketchingStore, feedbackStore, usersStore.currentUser?._id)
    let cam = await getCameraData(data, true, capture.name);
    const metadata = projectSettingStore.systemProjectSetting
    if (cam) {
      if (cam.cameraData) {
        cam = addViewPointCameraData(projectStore, commonStore, cam, metadata, viewerRef.current.cesiumElement);
      }
      cam.action = 'update'
      capturesStore
        .updateCaptureView(capture.id, cam, false, checkingUpdateProjectThumbnail('update', capture), {
          startTime,
          user: usersStore.currentUser,
          project: projectStore?.projectDetail?.name,
          organization: projectStore?.projectDetail?.organization?.name,
        }, updateProjectDetail)
        .then(() => {
          projectStore.setCurrentViewpoint({ ...capture, ...cam })
        })
    }
  }

  useEffect(() => {
    if (capturesStore.updateDataFromSocket) {
      let currentCapture = capturesStore.captureList?.length && capturesStore.captureList.find(el => el.id === capturesStore.updateDataFromSocket.viewpointId)

      if (capturesStore.updateDataFromSocket.type === 'update' && currentCapture) {
        const updateViewPointData = async () => {
          await updateCaptureViews(currentCapture)
        }
        updateViewPointData().finally(() => capturesStore.setUpdateDataFromSocket())
      } else if (capturesStore.updateDataFromSocket.type === 'rename' && currentCapture) {
        const updateViewPointName = async (newName, capture, captureName) => {
          if (newName) {
            if (captureName === newName) {
              setModelEdit(undefined)
              return
            }
            let cam = {}
            cam.name = newName
            const temp = [...captureList].sort((a, b) => a.name.localeCompare(b.name))
            let newIndex;
            let isUpdateThumnail = false;
            let oldIndex = temp.findIndex(c => c.id === capture.id);
            if (oldIndex !== -1) {
              temp[oldIndex].name = newName;
              const newtemp = temp.sort((a, b) => a.name.localeCompare(b.name))
              newIndex = newtemp.findIndex(c => c.id === capture.id);
            }
            if (oldIndex === 0 && newIndex !== 0 || oldIndex !== 0 && newIndex === 0) {
              isUpdateThumnail = projectStore?.projectDetail?.id;
            }
            if (!projectStore.projectDetail?.thumbnailFromViewpoint) {
              isUpdateThumnail = false;
            }
            cam.action = 'update'
            capturesStore
              .updateCaptureView(capture.id, cam, true, isUpdateThumnail, updateProjectDetail)
              .then(() => setModelEdit(undefined))
          }
        }
        updateViewPointName(capturesStore.updateDataFromSocket.newViewPointName, currentCapture, currentCapture.name).finally(() => capturesStore.setUpdateDataFromSocket())
      } else if (capturesStore.updateDataFromSocket.type === 'active' && currentCapture) {
        hanldeGoViewpoint(viewer, currentCapture)
        capturesStore.setUpdateDataFromSocket()
      } else if (capturesStore.updateDataFromSocket.type === 'delete' && currentCapture) {
        capturesStore.deleteCaptureViews(currentCapture.id, checkingUpdateProjectThumbnail('delete', currentCapture), updateProjectDetail).finally(() => capturesStore.setUpdateDataFromSocket())
      }
    }
  }, [capturesStore.updateDataFromSocket])

  const updateProjectDetail = (data) => {
    projectStore.setProjectDetail({ ...projectStore.projectDetail, thumbnail: data.thumbnail })
  }

  const hanldeGoViewpoint = (viewer, capture) => {
    let name = encodeURIComponent(capture.name);
    history.push(`${location.pathname}?viewpoint=${name}`)
    goViewpoint(viewer, capture)
    projectStore.setCurrentViewpoint(capture)
    projectStore.setTypeViewPoint('viewpoint')
    projectStore.setTargetViewPoint()
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const _viewpointname = params.get('viewpoint');
    if (_viewpointname && projectStore.projectDetail._id) {
      let _viewpointrecord = projectStore.projectDetail.viewpoints.find(x => x.name.toLowerCase() === _viewpointname.toLowerCase())
      if (_viewpointrecord) {
        capturesStore.toggleDrawerCaptureVisible(true)
        hanldeGoViewpoint(viewer, _viewpointrecord)
      } else {
        history.push(location.pathname)
      }
      projectStore.setVisitedMode(false)
    } else if (params.get('topic')) {
      onCloseViewpointlist()
    }

    return () => {

    }
  }, []);

  const onCloseViewpointlist = () => {
    capturesStore.toggleDrawerCaptureVisible(false)
    const params = new URLSearchParams(location.search);
    const _viewpointname = params.get('viewpoint');
    if (_viewpointname) {
      history.push(location.pathname)
    }
  };

  return (
    <Drawer
      closable={true}
      maskClosable={false}
      destroyOnClose={true}
      mask={false}
      className={`${!usersStore.cookie ? "re-order-has-cookie views-drawer" : "views-drawer"}`}
      id={'views-drawer'}
      onClose={onCloseViewpointlist}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {!projectStore.visitedMode && drawerTitle()}
          <div style={{ marginRight: 30 }}>
            <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <div>
                <HelpButton styles={'color: white;'} helppage={'viewpoint'} hovered={'gray'} />
              </div>
            </Tooltip>
          </div>
        </div>
      }
      placement={'bottom'}
      keyboard={true}
      visible={capturesStore.isDrawerCaptureVisible}
    >
      <CustomDrawerWraper>
        <CardViewWrapper>
          {captureList.length === 0 ? (
            t('no-view')
          ) : (
            <Slider {...sliderSetting}>
              {captureList
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((capture, index) => (
                  <Card
                    key={index}

                    cover={
                      <Fragment>
                        <ViewPointImageContainer
                          style={{ position: "relative", paddingBottom: "56.2%" }}
                        >
                          <ViewPointImage
                            className="ratio-images"
                            alt={capture.image ? capture.image.name : ''}
                            src={
                              capture.image
                                ? capture.image.url
                                : '/viewpoint-noimage.jpg'
                            }
                            onClick={() => hanldeGoViewpoint(viewer, capture)}
                          />
                        </ViewPointImageContainer>
                        {!projectStore.visitedMode && adminStore.checkingFeatureRole(projectStore, "feature_save_view_edit") && (
                          <>
                            <Popconfirm
                              onConfirm={() => {
                                capturesStore.deleteCaptureViews(capture.id, checkingUpdateProjectThumbnail('delete', capture), {
                                  startTime: Date.now(),
                                  user: usersStore.currentUser,
                                  project: projectStore?.projectDetail?.name,
                                  organization: projectStore?.projectDetail?.organization?.name,
                                }, updateProjectDetail)
                              }

                              }
                              okText={t('commons.delete')}
                              cancelText={t('commons.cancel')}
                              okType={'danger'}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              title={t('are-you-sure')}>
                              <Avatar
                                className='anticon'
                                key="delete"
                                shape="square"
                                icon={<SVGIcon color={'#F26524'} content={<ViewpointDelete />} width={18} height={18} />}
                              />
                            </Popconfirm>
                            <Popconfirm
                              onConfirm={() => updateCaptureViews(capture)}
                              okText={t('commons.update')}
                              cancelText={t('commons.cancel')}
                              okType={'primary'}
                              icon={<QuestionCircleOutlined style={{ color: '#F26524' }} />}
                              title={t('are-you-sure-updated-this-viewpoint')}>
                              <Avatar className='save-action'
                                key="update"
                                shape="square"
                                icon={<SVGIcon color={'#F26524'} content={<ViewpointUpdate />} width={22} height={25} />}
                              />
                            </Popconfirm>
                          </>
                        )}
                      </Fragment>
                    }>
                    {!projectStore.visitedMode && modelEdit !== undefined && modelEdit === capture.id ? (
                      <Input
                        size={'small'}
                        ref={inputEl}
                        defaultValue={capture.name}
                        type="text"
                        onKeyPress={e =>
                          onSaveHandler(e.key, capture, capture.name)
                        }
                        placeholder={capture.name}
                      />
                    ) : (
                      <div className="ant-card-meta">
                        <div className="ant-card-meta-detail">
                          <div
                            className="ant-card-meta-title"
                            onClick={adminStore.checkingFeatureRole(projectStore, "feature_save_view_edit") ? (e => onClickHandler(e, capture.id)) : (() => { })}
                          >
                            {capture.name}
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                ))}
            </Slider>
          )}
        </CardViewWrapper>
      </CustomDrawerWraper>
    </Drawer>
  )
}

export default inject(
  'capturesStore',
  'projectStore',
  'schedulingStore',
  'adminStore',
  'feedbackStore',
  'sketchingStore',
  'topicStore',
  'commonStore',
  'projectSettingStore',
  'polylineCutToolStore',
  'usersStore'
)(observer(DrawerCaptureViews))
